<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Employee Sync Settings
    </div>


    <div v-if="editDefaultFields === -1" style="overflow-y:auto;">
      <table class="table" style="text-align: left;">
        <tr>
          <th><b>Software</b></th>
          <td><b>Software ID</b> (softwareId)</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>Unternehmen Externe ID </b> (externalId)</td>
          <td><b>Lohn-/Gehaltszettel PDF Passwort </b> (payslipPassword)</td>
          <td><b>Finanzamt Lfd-Nr. </b> (taxOfficeSerialNumber)</td>
          <td><b>Gemeinde Lfd-Nr. </b> (municipalitySerialNumber)</td>
          <td><b>Kostenstelle </b> (costCenter)</td>
          <td><b>Standard Abrechnungskreis </b> (allocationGroup)</td>
          <td><b>Bank Auftraggeber </b> (bankAccountOriginator)</td>
          <td><b>Standard FIBU-Kreis </b> (defaultFinancialAccountGroupId)</td>
          <td><b>Nachname in BLOCKSCHRIFT exportieren </b> (exportLastNameBlockLetters)</td>
          <td><b>Keine externen Mitarbeiter exportieren </b> (ignoreExternalEmployees)</td>
          <td><b>Keine versteckten Mitarbeiter exportieren </b> (ignoreHiddenEmployees)</td>
          <td><b>Keine inaktiven Mitarbeiter exportieren </b> (ignoreInactiveEmployees)</td>
          <td><b>Nur aktuellsten Vertrag exportieren </b> (exportLatestContractOnly)</td>
          <td><b>Direkten Export ins LV Tool aktivieren </b> (enableExportViaTransferService)</td>
          <td><b>Periodischen Export aktivieren </b> (enablePeriodicExport)</td>
          <td><b>Mitarbeiterdaten Änderungsmodus </b> (employeeChangeMode)</td>
          <td><b>Wochenmodelle </b> (contractWeekModelNr)</td>
          <td><b>Kollektivverträge </b> (collectiveContracts)</td>
          <td><b>Tarifgruppen </b> (wageGroups)</td>
          <td><b>Bewertungsmodelle </b> (valuationModels)</td>
          <td><b>Standorte </b> (externalStoreId)</td>
        </tr>
        <tr v-for="setting in employeeSyncSettings" v-bind:key="'s'+setting.id" style="text-align: center">
          <th>{{softwareIds[setting.softwareId] !== undefined ? softwareIds[setting.softwareId] : "unbekannte Software ID " }}</th>
          <td>{{setting.softwareId}}</td>
          <td style="color: var(--ml)">
            <i class="fa fa-trash" style="cursor: pointer"
               v-tooltip="'Löschen'"
               v-on:click="deleteEmployeeSyncSettings(setting.id)"
            />
          </td>
          <td style="color: var(--ml)">
            <i class="fa fa-pen" style="cursor: pointer"
               v-tooltip="'Standardfelder bearbeiten'"
               v-on:click="editDefaultFields = setting.softwareId;"
               :class="{ disabled: editing}"
            />
          </td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-eid'"
                 v-bind:class="{ false: setting.externalId === 0, true: setting.externalId === 1 }"
                 v-on:click="setting.externalId = setting.externalId === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-psp'"
                 v-bind:class="{ false: setting.payslipPassword === 0, true: setting.payslipPassword === 1 }"
                 v-on:click="setting.payslipPassword = setting.payslipPassword === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-tsn'"
                 v-bind:class="{ false: setting.taxOfficeSerialNumber === 0, true: setting.taxOfficeSerialNumber === 1 }"
                 v-on:click="setting.taxOfficeSerialNumber = setting.taxOfficeSerialNumber === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-msn'"
                 v-bind:class="{ false: setting.municipalitySerialNumber === 0, true: setting.municipalitySerialNumber === 1 }"
                 v-on:click="setting.municipalitySerialNumber = setting.municipalitySerialNumber === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-cc'"
                 v-bind:class="{ false: setting.costCenter === 0, true: setting.costCenter === 1 }"
                 v-on:click="setting.costCenter = setting.costCenter === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-ag'"
                 v-bind:class="{ false: setting.allocationGroup === 0, true: setting.allocationGroup === 1 }"
                 v-on:click="setting.allocationGroup = setting.allocationGroup === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-bao'"
                 v-bind:class="{ false: setting.bankAccountOriginator === 0, true: setting.bankAccountOriginator === 1 }"
                 v-on:click="setting.bankAccountOriginator = setting.bankAccountOriginator === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-dfagi'"
                 v-bind:class="{ false: setting.defaultFinancialAccountGroupId === 0, true: setting.defaultFinancialAccountGroupId === 1 }"
                 v-on:click="setting.defaultFinancialAccountGroupId = setting.defaultFinancialAccountGroupId === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-elnbl'"
                 v-bind:class="{ false: setting.exportLastNameBlockLetters === 0, true: setting.exportLastNameBlockLetters === 1 }"
                 v-on:click="setting.exportLastNameBlockLetters = setting.exportLastNameBlockLetters === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-ihe'"
                 v-bind:class="{ false: setting.ignoreExternalEmployees === 0, true: setting.ignoreExternalEmployees === 1 }"
                 v-on:click="setting.ignoreExternalEmployees = setting.ignoreExternalEmployees === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-ihe'"
                 v-bind:class="{ false: setting.ignoreHiddenEmployees === 0, true: setting.ignoreHiddenEmployees === 1 }"
                 v-on:click="setting.ignoreHiddenEmployees = setting.ignoreHiddenEmployees === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-iie'"
                 v-bind:class="{ false: setting.ignoreInactiveEmployees === 0, true: setting.ignoreInactiveEmployees === 1 }"
                 v-on:click="setting.ignoreInactiveEmployees = setting.ignoreInactiveEmployees === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-elco'"
                 v-bind:class="{ false: setting.exportLatestContractOnly === 0, true: setting.exportLatestContractOnly === 1 }"
                 v-on:click="setting.exportLatestContractOnly = setting.exportLatestContractOnly === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-eevts'"
                 v-bind:class="{ false: setting.enableExportViaTransferService === 0, true: setting.enableExportViaTransferService === 1 }"
                 v-on:click="setting.enableExportViaTransferService = setting.enableExportViaTransferService === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-epe'"
                 v-bind:class="{ false: setting.enablePeriodicExport === 0, true: setting.enablePeriodicExport === 1 }"
                 v-on:click="setting.enablePeriodicExport = setting.enablePeriodicExport === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-ecm'"
                 v-bind:class="{ false: setting.employeeChangeMode === 0, true: setting.employeeChangeMode === 1 }"
                 v-on:click="setting.employeeChangeMode = setting.employeeChangeMode === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-cwmn'"
                 v-bind:class="{ false: setting.contractWeekModelNr === 0, true: setting.contractWeekModelNr === 1 }"
                 v-on:click="setting.contractWeekModelNr = setting.contractWeekModelNr === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-cc'"
                 v-bind:class="{ false: setting.collectiveContracts === 0, true: setting.collectiveContracts === 1 }"
                 v-on:click="setting.collectiveContracts = setting.collectiveContracts === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-wg'"
                 v-bind:class="{ false: setting.wageGroups === 0, true: setting.wageGroups === 1 }"
                 v-on:click="setting.wageGroups = setting.wageGroups === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-vm'"
                 v-bind:class="{ false: setting.valuationModels === 0, true: setting.valuationModels === 1 }"
                 v-on:click="setting.valuationModels = setting.valuationModels === 0 ? 1 : 0"></i></td>
          <td><i class="fas fa-check-circle checkbox" :key="'cb-sess-esid'"
                 v-bind:class="{ false: setting.externalStoreId === 0, true: setting.externalStoreId === 1 }"
                 v-on:click="setting.externalStoreId = setting.externalStoreId === 0 ? 1 : 0"></i></td>
        </tr>
      </table>
    </div>
    <div v-else style="display: flex; justify-content:center; overflow-y:auto;">
      <div style="display: flex; justify-content:center; width: 900px;">
        <datatablesimple style="flex-basis: calc(100% - 25px)" :state="fieldsState"/>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'superadminEmployeeSyncSettings',
  data () {
    return {
      softwareIds: {
        0: "BMD",
        1: "DPW",
        2: "DATEV",
      },
      employeeSyncFields: [],
      employeeSyncSettings: [],
      employeeSyncDefaultFields: [],

      editing: false,
      saving: false,
      editDefaultFields: -1,
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser']),
    dateDisplay: function() {
      return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
    },
    headerButtons() {
        let view = this;
        let state = {
          buttons: [
            {
              type: "block",
              icon: "far fa-plus",
              click: () => {
                view.openNewSoftwareSettings();
              },
              show: !view.editing && view.editDefaultFields === -1,
              tooltip: 'Settings für neues LV Tool anlegen',
              title: 'Settings für neues LV Tool anlegen'
            },
            {
              type: "block green",
              icon: "fa-save",
              click: () => {
                this.saving = true;
                this.saveEmployeeSyncSettings();
              },
              show: view.editing && !view.loading,
              disabled: view.saving,
              tooltip: "Änderungen speichern",
              title: "Änderungen speichern"
            },
            {
              type: "block red",
              icon: "fa-minus-circle",
              click: () => {
                this.refresh();
              },
              show: view.editing && !view.loading,
              disabled: view.saving,
              tooltip: "Änderungen speichern",
              title: "Änderungen speichern"
            },
            {
              type: "block",
              icon: "fa-chevron-left",
              click: () => {
                view.editDefaultFields = -1;
              },
              show: view.editDefaultFields !== -1,
              tooltip: "Änderungen speichern",
              title: "Änderungen speichern"
            }
          ]
        };
        /*
        state.buttons.push({ type: "block", icon: "fa-eye", click: () => { }, tooltip: 'VIEW' });
        state.buttons.push({ type: "block", icon: "fa-cloud-download-alt", click: () => { view.loadExternalUsersFromKundencenter(); }, tooltip: 'SYNC' });
        */
        return state;
    },
    timeZoneList() {
        let result = [];
        /*
        this.timeZones.forEach(i => {
            let item = {label: i, value: i};
            result.push(item);
        })
        */
        return result;
    },
    fieldsState() {
      let view = this;
      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          listStructure: "Standardfelder",
          columnInternalIdentifier: "Feldbezeichnungen",
          columnExternalDefaultName: "Standard Externe Bezeichnung",
        },
        Object.values(view.employeeSyncDefaultFields[view.editDefaultFields])
      );

      state.headerMap.listStructure.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "buttonc",
            label: data.fieldIdentifier,
            type: "occupy-container",
            tooltip: "Auswählen / Abwählen",
            class: `${data.showField === 1 ? "selected" : ""}`,
            customEvents: {
              click: () => {
                data.showField === 1 ? data.showField = 0 : data.showField = 1;
                view.saveEmployeeSyncFieldSettings(view.editDefaultFields);
                view.$forceUpdate();
              },
            },
            style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
            styleLabel: {"text-align": "left", width: "100%"},
          };
        },
      ];
      state.headerMap.columnInternalIdentifier.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "inputc",
            inputProp: data.fieldInternalName,
            placeholder: data.fieldInternalName,
            style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
            customEvents: {
              Update: (val) => {
                data.fieldInternalName = val;
                view.saveEmployeeSyncFieldSettings(view.editDefaultFields);
                view.$forceUpdate();
              },
            },
          };
        },
      ];
      state.headerMap.columnExternalDefaultName.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "inputc",
            inputProp: data.fieldName,
            placeholder: data.fieldName,
            style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
            customEvents: {
              Update: (val) => {
                data.fieldName = val;
                view.saveEmployeeSyncFieldSettings(view.editDefaultFields);
                view.$forceUpdate();
              },
            },
          };
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = {"justify-content": "start"}));
      state.compact = true;
      state.draggable = true;
      state.dragOptions = {
        handle: ".fa-up-down",
        onStart: function (/**Event*/event) {
          // remove strange "glow" from "transform: translateZ(0px)"
          event.item.style.transform = null;
        },
        onUpdate(/**Event*/event) {
          view.updateFieldOrder(event.oldIndex + 1, event.newIndex + 1);
          view.saveEmployeeSyncFieldSettings(view.editDefaultFields);
        }
      };
      return state;
    },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
    employeeSyncSettings: {
      handler (val, oldVal) {
        if (oldVal.length > 0) {
          this.editing = true;
        }
      },
      deep: true
    }
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
        let view = this;
        view.loading = true;
        view.loadEmployeeSyncSettings()
          .then(response => {
            view.employeeSyncSettings = response.data;
            view.loading = false;
            view.editing = false;
          }).catch(function (error) {
            console.log(error);
            view.loading = false;
            view.editing = false;
          });

        view.prepareDefaultFields();
    },

    loadEmployeeSyncSettings: function(force = false){
      let view = this;

      view.employeeSyncSettings = [];
      view.editing = false;

      let url = '/api/sec/employeesync/settings';
      const params = {
        companyId: this.$store.state.companyId,
      };

      return this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      });
    },
    saveEmployeeSyncSettings: function(force = false){
      let view = this;
      let promises = [];

      Object.values(view.employeeSyncSettings).forEach((setting) => {
        let url = '/api/sec/employeesync/settings/' + setting.id;
        const params = {
          companyId: this.$store.state.companyId,
        };

        promises.push(this.axios({
          method: 'put',
          headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
          url: url,
          params: params,
          data: setting,
        }).catch(function (error) {
          console.log(error);
          view.$helpers.error("Fehler", "Die Einstellungen konnten nicht verändert werden.");
        }));
      });

      Promise.all(promises).then((response) => {
        view.$helpers.success("Gespeichert", "Mitarbeiter Sync Settings wurde gespeichert");

        this.editing = false;
        this.saving = false;
      }).catch(function (error) {
        console.log(error);

        this.editing = false;
        this.saving = false;
      });

    },
    createEmployeeSyncSettings: function(softwareId){
      let view = this;
      let url = '/api/sec/employeesync/settings';
      const params = {
        companyId: this.$store.state.companyId,
        softwareId: softwareId
      };

      this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then(response => {
        view.$helpers.success("Gespeichert", "Mitarbeiter Sync Setting wurde erstellt.");
        view.checkAndInitiateDefaultFields(softwareId);
        view.refresh();
      }).catch(function (error) {
        view.$helpers.error("Fehler", "Die gewünschte Software ID existiert bereits.");
      })
    },
    deleteEmployeeSyncSettings: function(id){
      let view = this;
      let url = '/api/sec/employeesync/settings/' + id;
      const params = {
        companyId: this.$store.state.companyId
      };

      this.axios({
        method: 'delete',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then(response => {
        view.$helpers.success("Gelöscht", "Mitarbeiter Sync Setting wurde gelöscht.");
        view.refresh();
      }).catch(function (error) {
        view.$helpers.error("Fehler", "Die Einstellungen konnten nicht gelöscht werden.");
      })
    },
    openNewSoftwareSettings(){
      let view = this;
      let dataEntries = [
        {label: 'Software ID: ', key: 'softwareId', hidden: false, type: 'text', value: '', length: 128,styles: {'width': '350px'}},
        {flexDivider: true, styles: {'margin-bottom': '23px'}},
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Anlegen",
          entries: dataEntries,
          selectCallback: function (newSoftwareSettings) {
            view.createEmployeeSyncSettings(newSoftwareSettings.softwareId);
          },
          errorCheck: {
          }
        },
        header: 'Settings für neues LV Tool anlegen',
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },

    prepareDefaultFields() {
      let view = this;
      view.loadEmployeeSyncFields()
        .then((syncFieldsResponse) => {
          view.loadEmployeeSyncFieldSettingDefaults()
            .then((response) => {
              view.employeeSyncDefaultFields = [];
              Object.values(response.data).forEach(element => {
                if (!(element.softwareId in view.employeeSyncDefaultFields)) {
                  view.employeeSyncDefaultFields.push(element.softwareId);
                  view.employeeSyncDefaultFields[element.softwareId] = [];
                }
                element.fieldIdentifier = view.employeeSyncFields[element.fieldId];
                view.employeeSyncDefaultFields[element.softwareId].push(element);
              });
            });
        });
    },
    loadEmployeeSyncFields() {
      let view = this;

      let method = 'get';
      let baseUrl = '/api/sec/employeesync/fields';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then(response => {
        view.employeeSyncFields = [];
        Object.values(response.data).forEach(field => {
          view.employeeSyncFields.push(field.id);
          view.employeeSyncFields[field.id] = field.fieldName;
        });
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Laden der Mitarbeiter Sync Feld Default Einstellungen."
        );
      });
    },
    loadEmployeeSyncFieldSettingDefaults() {
      let view = this;

      let method = 'get';
      let baseUrl = '/api/sec/employeesync/fields/settings/default';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Laden der Mitarbeiter Sync Feld Default Einstellungen."
        );
      });
    },
    saveEmployeeSyncFieldSettings(softwareId) {
      let view = this;

      let method = 'post';
      let baseUrl = '/api/sec/employeesync/fields/settings/default/all';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("softwareId", softwareId, url, baseUrl);

      let payload = {
        'data': view.employeeSyncDefaultFields[softwareId]
      }

      this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url,
        data: payload
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Speichern der Standardfelder."
        );
      });
    },
    checkAndInitiateDefaultFields(softwareId) {
      let view = this;

      if (!(softwareId in view.employeeSyncDefaultFields)) {
        view.employeeSyncDefaultFields.push(softwareId);
        view.employeeSyncDefaultFields[softwareId] = [];

        let method = 'post';
        let baseUrl = '/api/sec/employeesync/fields/settings/default/create';
        let url = baseUrl;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
        url = this.$helpers.appendParam("softwareId", softwareId, url, baseUrl);

        let payload = {}

        this.axios({
          method: method,
          headers: {
            'AUTHORIZATION': "Bearer " + this.$store.state.jwt
          },
          url: url,
          data: payload
        }).catch(function (error) {
          view.$helpers.error(
            "Fehler",
            "Fehler beim Erstellen der Standardfelder."
          );
        });

      }
    },

    updateFieldOrder(from, to) {
      let view = this;

      Object.values(view.employeeSyncDefaultFields[view.editDefaultFields]).forEach((element) => {
        if (element.fieldOrder === from) {
          //console.log(element.fieldOrder + " to " + to);
          element.fieldOrder = to;
        } else {
          if (from < to && element.fieldOrder > from && element.fieldOrder <= to) {
            //console.log(element.fieldOrder + " to " + (element.fieldOrder - 1));
            element.fieldOrder -= 1;
          } else if (from > to && element.fieldOrder < from && element.fieldOrder >= to) {
            //console.log(element.fieldOrder + " to " + (element.fieldOrder + 1));
            element.fieldOrder += 1;
          }
        }
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

tr {
  display: inline-flex;
  flex-direction: column;
}

</style>
